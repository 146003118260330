import React from "react";
import MailIcon from "@heroicons/react/outline/MailIcon";
import PhoneIcon from "@heroicons/react/outline/PhoneIcon";
import ProgressiveImage from "react-progressive-graceful-image";

export default function About({social}) {
return <div className="relative bg-gray-900" id={"contact"}>
    <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
            <div className="h-full xl:relative xl:col-start-2">
              <ProgressiveImage src={'helpDesk_small.jpg'} placeholder={'helpDesk_small.jpg'}>
                {(src) =>
                  <img
                    className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                    src={src}
                    alt="People working on laptops"
                  />
                }
              </ProgressiveImage>
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                />
            </div>
        </div>
    </div>
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative max-w-7xl mx-auto">
            <div className="py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                <div className="max-w-lg mx-auto">
                    <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">Get in touch</h2>
                    <p className="mt-3 text-lg leading-6 text-gray-500">
                        No project is too large or too small for <strong>Presidential Kitchen and Bath</strong>
                    </p>
                    <dl className="mt-8 text-base text-gray-500">
                        <div>
                            <dd>
                                <p>Servicing</p>
                                <p>DC, MD and VA</p>
                            </dd>
                        </div>
                        <div className="mt-6">
                            <dt className="sr-only">Phone number</dt>
                            <a className="flex" href={'tel:+1-240-405-9487'}>
                                <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                <span className="ml-3">240.405.9487</span>
                            </a>
                        </div>
                        <div className="mt-3">
                            <dt className="sr-only">Email</dt>
                            <a className="flex"  href={'mailto:jacobmheaton@outlook.com'}>
                                <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                <span className="ml-3">jacobmheaton@outlook.com</span>
                            </a>
                        </div>
                    </dl>
                  <dl className="mt-8 text-base text-gray-500">
                    <div className="flex space-x-6 mt-10 md:order-2">
                    {social && social.map((item) => (
                      <a key={item.name} href={item.href} className="text-gray-400 hover:text-white">
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </a>
                    ))}
                    </div>
                  </dl>
                </div>
            </div>
        </div>
    </div>
</div>
}
