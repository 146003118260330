import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Quote from './Quote'

export default function Testimony({testimonyContent: {sectionHeader, sectionSubHeader, testimonies}}) {

    return (
        <div className="relative pt-16 pb-32 overflow-hidden" id={"testimonies"}>
            <h2 className="text-4xl text-center">{sectionHeader}</h2>
            <h3 className="text-2xl text-center">{sectionSubHeader}</h3>
            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid grid-flow-row grid-cols-2 lg:gap-24">
                  {
                    testimonies && testimonies.map(testimony => (
                      <div className="mt-8 border-t border-gray-200 pt-6 p-6" key={testimony.author}>
                        <Quote author={testimony.author} quote={testimony.testimony}/>
                      </div>
                    ))
                  }
                </div>
            </div>
        </div>

    )
}
