import React from 'react'
import Header from './header'
import Hero from './hero'
import LogoCloud from './logoCloud'
import Testimony from './testimony'
import Services from './services'
import About from './about'
import Footer from './footer'
import Gallery from './gallery'
import {social, hero, testimonyContent, serviceContent} from './constants'

export default function Example() {
  return (
      <div className="bg-white">
        <Header/>
        <main>
          <Hero heroContent={hero}/>
          <LogoCloud social={social}/>
          <Gallery/>
          <Testimony testimonyContent={testimonyContent}/>
          <Services serviceContent={serviceContent}/>
          <About social={social}/>
        </main>
        <Footer social={social}/>
      </div>
  )
}
