import {Popover, Transition} from "@headlessui/react";
import {MenuIcon} from "@heroicons/react/outline/esm";
import React, {Fragment} from "react";

export default function Header() {
    return (
        <header>
            <Popover className="relative bg-white">
                <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <a href="/"  className="flex justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-auto sm:h-10" viewBox="0 0 20 20" fill="#6366f1">
                                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                            </svg>
                            <h1 className="align-baseline text-2xl font-semibold text-indigo-500 pt-2" >
                                PRESIDENTIAL
                                <small className="font-normal lowercase hidden sm:inline"> Kitchen and Bath</small>
                            </h1>
                        </a>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Open menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <Popover.Group as="nav" className="hidden md:flex space-x-10">
                        <a href="/#services" className="text-base font-medium text-gray-500 hover:text-gray-900">
                            Services
                        </a>
                        <a href="/#testimonies" className="text-base font-medium text-gray-500 hover:text-gray-900">
                            Testimonies
                        </a>
                        <a href="/#contact" className="text-base font-medium text-gray-500 hover:text-gray-900">
                            Contact Us
                        </a>
                    </Popover.Group>
                </div>

                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                    >
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                            <div className="py-6 px-5">
                                <div className="grid grid-cols-2 gap-4">
                                    <a href="/#services" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                        Services
                                    </a>
                                    <a href="/#testimonies" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                        Testimonials
                                    </a>
                                    <a href="/#contact" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                        Contact Us
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
        </header>

    )
}
