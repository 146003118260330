import ProgressiveImage from "react-progressive-graceful-image";
export default function Hero({heroContent: { image: { path, smallPath ,altTag}, mainHeader, subHeader}}) {

    return (
        <div className="relative">
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
            <div className="mx-auto">
                <div className="relative shadow-xl  sm:overflow-hidden">
                    <div className="absolute inset-0">
                        <ProgressiveImage src={path} placeholder={smallPath}>
                            {(src) => <img src={src} alt={altTag} className="h-full w-full object-cover"/>}
                        </ProgressiveImage>

                    </div>
                    <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-64 lg:px-64 ">
                        <div className="bg-indigo-500 bg-opacity-80 rounded-md">
                            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl 	">
                                {mainHeader && <span className="block text-white">{mainHeader}</span>}
                                {subHeader && <span className="block text-indigo-200">{subHeader}</span>}
                            </h1>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
