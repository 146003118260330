import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCaMBjVY1sxsePNz1y3ga-B7mYEoFNWoWw",
  authDomain: "presedential-kitchen-and-bath.firebaseapp.com",
  projectId: "presedential-kitchen-and-bath",
  storageBucket: "presedential-kitchen-and-bath.appspot.com",
  messagingSenderId: "712746488495",
  appId: "1:712746488495:web:69db3516f9528882803a5a",
  measurementId: "G-TCLMTXN00J"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'loaded');
