import React from "react";

export default function LogoCloud({social}) {

    return (
        <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
          Follow us on Social Media
        </p>
        <div className="mt-6 flex justify-center ">
          {social && social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500 flex justify-center px-4 md:px-16">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-12 w-12" aria-hidden="true" />
              </a>
          ))}
        </div>
      </div>
    </div>
    )
}
