import React from "react";

export default function Services({serviceContent: { sectionHeader, sectionSubHeader, services}}) {
return (
    <div className="bg-gradient-to-r from-purple-800 to-indigo-700" id={"services"}>
        <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
            <h2 className="text-3xl font-extrabold text-white tracking-tight">{sectionHeader}</h2>
            <p className="mt-4 max-w-3xl text-lg text-purple-200">
               {sectionSubHeader}
            </p>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-12 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
                {services.map((feature) => (
                    <div key={feature.name}>
                        <div className="mt-2">
                            <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                            <p className="mt-2 text-base text-purple-200">{feature.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <p className="mt-12 max-w-3xl text-lg text-purple-200">
               And More!
            </p>
        </div>
    </div>
)
}
