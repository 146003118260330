import React, {useState} from "react";
import Lightbox from 'react-image-lightbox';
import {logAnalytics} from "./analytics";
import ProgressiveImage from "react-progressive-graceful-image";
const images = [
    {
        key: 0,
        section: [
            {alt: 'Bathroom Shower', path: 'bathroom_01_01.jpg', index: 0, smallPath: 'bathroom_01_01.jpg'},
            {alt: 'Bathroom', path: 'bathroom_01_03.jpg', index: 1, smallPath: 'bathroom_01_03.jpg'},
            {alt: 'Bathroom', path: 'bathroom_01_02.jpg', index: 2, smallPath: 'bathroom_01_02.jpg'},
        ]
    },
    {
        key: 1,
        section: [
            {alt: 'Bathroom Sink', path: 'bathroom_02_02.jpg', index: 3, smallPath: 'bathroom_02_02.jpg'},
            {alt: 'Bathroom', path: 'bathroom_02_01.jpg', index: 4, smallPath: 'bathroom_02_01.jpg'},
            {alt: 'Bathroom', path: 'bathroom_02_05.jpg', index: 5, smallPath: 'bathroom_02_05.jpg'}
        ]
    },
    {
        key: 2,
        section: [
            {alt: 'New fence installation', path: 'fence_01_01.jpg', index: 6,  smallPath: 'fence_01_01.jpg'},
            {alt: 'New fence installation', path: 'fence_01_02.jpg', index: 7,  smallPath: 'fence_01_02.jpg'},
            {alt: 'Fresh coat of paint ', path: 'paint_01_01.jpg', index: 8,  smallPath: 'paint_01_01.jpg'}
        ]
    },
];
let allImages = [];
images.forEach(section => allImages.push(...section.section));

export default function Gallery() {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    return (
        <section className="overflow-hidden text-gray-700 ">
            <div className="container px-5 py-2 mx-auto lg:pt-24 lg:pb-24 lg:px-32 ">
                <div className="flex flex-wrap -m-1 md:-m-2 ">
                    {images.map(section => (
                        <div className="flex flex-wrap md:w-1/3 h-screen w-full" key={section.key + '-wrapper'}>
                            <div className="w-1/2 p-1 md:p-2">
                              <ProgressiveImage src={section.section[0].path} placeholder={section.section[0].smallPath}>
                                {(src) =>
                                <img alt={section.section[0].alt}
                                     className="block cursor-pointer object-contain object-cover object-center w-full h-full rounded-lg shadow-xl"
                                     src={src}
                                     onClick={() => {
                                         setPhotoIndex(section.section[0].index);
                                         setIsOpen(!isOpen)
                                     }}
                                />
                                }

                              </ProgressiveImage>
                            </div>
                            <div className="w-1/2 p-1 md:p-2">
                              <ProgressiveImage src={section.section[1].path} placeholder={section.section[1].smallPath}>
                                {(src) =>
                                  <img alt={section.section[1].alt}
                                       className="block cursor-pointer object-contain object-cover object-center w-full h-full rounded-lg shadow-xl"
                                       src={src}
                                       onClick={() => {
                                         setPhotoIndex(section.section[1].index);
                                         setIsOpen(!isOpen)
                                       }}
                                  />
                                }
                              </ProgressiveImage>
                            </div>
                            <div className="w-full p-1 md:p-2">
                              <ProgressiveImage src={section.section[2].path} placeholder={section.section[2].smallPath}>
                                {(src) =>
                                  <img alt={section.section[2].alt}
                                       className="block cursor-pointer object-contain object-cover object-center w-full h-full rounded-lg shadow-xl"
                                       src={src}
                                       onClick={() => {
                                         setPhotoIndex(section.section[2].index);
                                         setIsOpen(!isOpen)
                                       }}
                                  />
                                }
                              </ProgressiveImage>
                            </div>
                        </div>
                    ))}
                    {isOpen && (
                        <Lightbox
                            onCloseRequest={() => setIsOpen(false)}
                            onImageLoad={(imageSrc, srcType, image) => {
                              logAnalytics('gallery_image_view', {
                                imageSrc, srcType
                              })
                            }}
                            mainSrc={allImages[photoIndex].path}
                            nextSrc={allImages[(photoIndex + 1) % allImages.length].path}
                            prevSrc={allImages[(photoIndex + images.length - 1) % allImages.length].path}
                            onMovePrevRequest={() =>
                                setPhotoIndex((photoIndex - 1) % allImages.length)
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % allImages.length)
                            }
                        />
                    )}
                </div>
            </div>
        </section>
    )
}
